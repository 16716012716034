/* eslint-disable react/prop-types */
import React from 'react';
import { Archive as ArchivedIcon, Star as ActiveIcon } from '@material-ui/icons';
import { fragments } from './fragments';
import { yesNoOptions } from './utils';

export default {
  types: {
    job: {
      renderers: {
        inline: ({ node }) => <>{node.shortId} - {node.title}</>,
      },

      selections: {
        default: `
          ${fragments.job.basic}
          tasks { id task {
            ${fragments.task.basic}
          }}
          files(order_by: {file: {updatedAt: desc}}) { id file {
            ${fragments.file.basic}
          }}
          forms {id form{id title jobs {id job{id}}}}
          tags {id tag {id label type color icon}}
          firstWorkEntry: workEntries(limit: 1, order_by: [{start: asc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          lastWorkEntry: workEntries(limit: 1, order_by: [{start: desc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          workEntries_aggregate {
            aggregate {
              sum {
                duration
              }
            }
          }
          assignedUsers {id user {
            id displayName email
            avatar {id url mimeType name }
          }}
          notedWorkEntries: workEntries(where: {notes: { _neq: "" }}){
            id start end duration notes
            user {id displayName avatar{id url}}
          }
          expenses_aggregate{aggregate{count}}
          change_orders_aggregate: forms_aggregate(where: {form: {name:{_eq: "ChangeOrderForm"}}}){aggregate{count}}
          resources{id resource{id title description link createdAt updatedAt userId expires files}}
          resources_aggregate{aggregate{count}}
        `,
      },

      fields: {
        files: {
          type: 'file',
          hasMany: true,
        },

        shortId: {
          type: 'String',
          label: 'Code',
          validation: {
            required: true,
          },
        },

        title: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        options: {
          type: 'Json',
        },

        specs: {
          type: 'Json',
        },

        customer: {
          type: 'contact',
          validation: {
            required: true,
          },
        },

        tasks: {
          type: 'task',
          hasMany: true,
          validation: {
            required: true,
          },
        },

        tags: {
          type: 'tag',
          hasMany: true,
          validation: { required: false },
        },

        forms: {
          type: 'form',
          hasMany: true,
          validation: { required: false },
        },

        assignedUsers: {
          type: 'user',
          hasMany: true,
          validation: { required: false },
        },

        scheduleDate: {
          type: 'Date',
          defaultValue: null,
          validation: { required: false },
        },

        status: {
          type: 'String',
          options: [
            { value: 'active', primary: 'Active' },
            { value: 'completed', primary: 'Completed' },
            { value: 'archived', primary: 'Archived' },
            { value: 'pending', primary: 'Pending' },
          ],
          defaultValue: 'active',
          validation: {
            required: true,
          },
        },

        resources: {
          type: 'resource',
          hasMany: true,
          validation: { required: false },
        },

        type: {
          type: 'String',
          validation: { required: true },
          options: [
            { value: 'welding', primary: 'Welding' },
            { value: 'construction', primary: 'Construction' },
            { value: 'repairs', primary: 'Repairs' },
            { value: 'other', primary: 'Other' },
          ],
        },

        'specs.ferryRequired': { type: 'String', options: yesNoOptions, validation: { required: false } },
        'specs.permits': { type: 'String', validation: { required: false } },
        'specs.customerPo': { type: 'String', validation: { required: false } },

        'specs.material': {
          type: 'String',
          options: [
            { value: 'aluminium', primary: 'Aluminium' },
            { value: 'steel', primary: 'Steel' },
            { value: 'stainless', primary: 'Stainless' },
            { value: 'hdpe', primary: 'HDPE' },
            { value: 'other', primary: 'Other' },
          ],
          validation: { requiredIf: { dep: 'type', eq: 'welding' } },
        },

        'specs.quantity': { type: 'String' },
        'specs.painted': { type: 'String', options: yesNoOptions, validation: { requiredIf: { dep: 'type', eq: 'welding' } } },
        'specs.paintingDetails': { type: 'String' },

        'specs.depositReceivedVia': { type: 'String', validation: { requiredIf: { dep: 'specs.dateDepositReceived', isTruthy: true } } },
        'specs.dateDepositReceived': { type: 'String' },
        'specs.billingInfoSameAsCustomer': { type: 'String', options: yesNoOptions, defaultValue: 'yes', validation: { required: true } },
        'specs.billingInfo': { type: 'String', validation: { requiredIf: { dep: 'specs.billingInfoSameAsCustomer', eq: 'no' } } },
        'specs.billStatus': { type: 'String', defaultValue: 'not-billed', validation: { required: true } },

        'specs.notes': { type: 'String', validation: { required: false } },
        'specs.bookkeeperNotes': { type: 'String', validation: { required: false } },
        'specs.officeManagerNotes': { type: 'String', validation: { required: false } },

      },
    },
  },
};
